import { ReactNode } from "react";

import { cn } from "@/lib/utils";

import { FormControl, FormItem, FormMessage } from "../ui/form";
import { Textarea, TextareaProps } from "../ui/textarea";

export function FormTextarea({
  field,
  nodeBeforeError,
  nodeAfterError,
  className,
  ...rest
}: TextareaProps & {
  field: any;
  nodeBeforeError?: ReactNode;
  nodeAfterError?: ReactNode;
}) {
  return (
    <FormItem>
      <FormControl>
        <Textarea
          className={cn(
            "border-0 border-b rounded-none border-b-[#F2EFEC] placeholder-[#84807D] px-0 focus:outline-none focus:border-b-primary !focus-visible:ring-offset-0 focus-visible:shadow-none !focus-visible:outline-none !ring-0 !ring-offset-0",
            className
          )}
          {...field}
          {...rest}
        />
      </FormControl>
      {nodeBeforeError}
      <FormMessage />
      {nodeAfterError}
    </FormItem>
  );
}
