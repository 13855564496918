"use client";

import React, { Fragment, useEffect, useState } from "react";

interface TimeLeft {
  days: number;
  hours: number;
  minutes: string;
  seconds: string;
}

interface CountdownProps {
  date?: string;
  duration?: number;
  onEnd?: () => void;
  component?: React.ComponentType<TimeLeft>;
  fallback?: React.ComponentType;
}

const calculateTimeLeft = (target: Date): TimeLeft => {
  const difference = +target - +new Date();

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.max(0, Math.floor((difference / 1000 / 60) % 60))
        .toString()
        .padStart(2, "0"),
      seconds: Math.max(0, Math.floor((difference / 1000) % 60))
        .toString()
        .padStart(2, "0"),
    };
  }

  return { days: 0, hours: 0, minutes: "00", seconds: "00" };
};

const DefaultCountdown: React.FC<TimeLeft & { ariaLabel?: string }> = ({
  days,
  hours,
  minutes,
  seconds,
  ariaLabel,
}) => (
  <div
    className="flex justify-center items-center space-x-4"
    role="timer"
    aria-label={ariaLabel || "Countdown timer"}
  >
    {!!days && (
      <div className="text-center">
        <div className="text-4xl font-bold" aria-hidden="true">
          {days}
        </div>
        <div className="text-sm" aria-hidden="true">
          Days
        </div>
        <span className="sr-only">{days} days</span>
      </div>
    )}
    {!!hours && (
      <div className="text-center">
        <div className="text-4xl font-bold" aria-hidden="true">
          {hours}
        </div>
        <div className="text-sm" aria-hidden="true">
          Hours
        </div>
        <span className="sr-only">{hours} hours</span>
      </div>
    )}
    <div className="text-center">
      <div className="text-4xl font-bold" aria-hidden="true">
        {minutes}
      </div>
      <div className="text-sm" aria-hidden="true">
        Minutes
      </div>
      <span className="sr-only">{minutes} minutes</span>
    </div>
    <div className="text-center">
      <div className="text-4xl font-bold" aria-hidden="true">
        {seconds}
      </div>
      <div className="text-sm" aria-hidden="true">
        Seconds
      </div>
      <span className="sr-only">{seconds} seconds</span>
    </div>
    <span className="sr-only" aria-live="polite">
      {days} days, {hours} hours, {minutes} minutes, and {seconds} seconds
      remaining
    </span>
  </div>
);

const INITIAL_STATE = {
  days: 0,
  hours: 0,
  minutes: "00",
  seconds: "00",
};

export const Countdown: React.FC<CountdownProps> = ({
  date,
  duration,
  onEnd,
  component: CountdownComponent = DefaultCountdown,
  fallback: FallbackComponent = Fragment,
}) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(INITIAL_STATE);
  const [isEnded, setIsEnded] = useState(false);

  useEffect(() => {
    let targetDate: Date;
    setIsEnded(false);

    if (date) {
      targetDate = new Date(date);
    } else if (duration) {
      targetDate = new Date(Date.now() + duration * 1000);
    } else {
      console.error(
        "Either date or duration must be provided to Countdown component"
      );
      return;
    }

    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(targetDate);
      setTimeLeft(newTimeLeft);

      if (
        newTimeLeft.days === 0 &&
        newTimeLeft.hours === 0 &&
        newTimeLeft.minutes === "00" &&
        newTimeLeft.seconds === "00"
      ) {
        clearInterval(timer);
        setIsEnded(true);
        onEnd?.();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [date, duration, onEnd]);

  if (isEnded) return <FallbackComponent />;
  return <CountdownComponent {...timeLeft} />;
};
