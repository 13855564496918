import { Loader2, X } from "lucide-react";

import { useCart } from "@/context";
import { ID } from "@/lib/schema";
import { apiImage, currencyFormat } from "@/lib/utils";

import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";

export function LineItem({
  productId,
  title,
  price,
  thumbnail,
  productType,
  loading,
}: {
  productId: ID;
  title: string;
  price: number;
  thumbnail?: string;
  productType: number;
  loading?: boolean;
}) {
  const { removeFromCart } = useCart();
  const type = ["Цомог", "Лекц", "Сургалт", "Ном", "Сургалтын багц", "Сэтгүүл"][
    productType
  ];
  return (
    <div
      className="bg-[#FBF9F8] rounded-xl p-5 flex gap-4 max-md:p-3 max-md:gap-3"
      itemScope
      itemType="https://schema.org/Offer"
    >
      <Avatar className="!rounded-sm">
        <AvatarImage
          src={apiImage(thumbnail, "xs")}
          alt="@shadcn"
          itemProp="image"
          loading="lazy"
        />
        <AvatarFallback>
          {title?.slice(0, 2).toLocaleUpperCase()}
        </AvatarFallback>
      </Avatar>
      <div
        className="space-y-2 truncate flex-1"
        itemScope
        itemType="https://schema.org/Product"
      >
        <div className="flex items-center justify-between gap-1 truncate">
          <p className="truncate flex-1 min-w-0 max-md:text-sm" itemProp="name">
            {title}
          </p>
          {loading ? (
            <Loader2 className="w-6 h-6 animate-spin text-slate-200" />
          ) : (
            <button
              type="button"
              className="w-6 h-6 flex items-center justify-center rounded-full hover:bg-slate-100"
              aria-label="Remove item"
              onClick={() => removeFromCart(productId)}
            >
              <X color="#84807D" size={18} />
            </button>
          )}
        </div>
        <div className="flex items-center justify-between gap-1 truncate">
          <span className="text-xs text-primary" itemProp="category">
            {type}
          </span>
          <span className="text-xs" itemProp="price">
            {currencyFormat(price)}
          </span>
        </div>
      </div>
    </div>
  );
}
