"use client";

import { ReactNode, useMemo } from "react";
import { useParams, usePathname } from "next/navigation";

type RestrictedRoute = {
  path: string;
  exact?: boolean;
  params?: string[];
};

const restrictedRoutes: RestrictedRoute[] = [
  { path: "/checkout", exact: false },
  { path: "/albums", exact: true },
  { path: "/albums/:id/:track", params: ["id", "track"] },
  { path: "/search", exact: true },
  { path: "/podcasts", exact: true },
  { path: "/videos", exact: true },
  { path: "/mood", exact: false },
  { path: "/courses", exact: true },
  { path: "/articles", exact: true },
  { path: "/account", exact: true },
  { path: "/account/edit", exact: true },
  { path: "/account/pin", exact: true },
  { path: "/account/faq", exact: true },
  { path: "/account/terms", exact: true },
  { path: "/policy", exact: true },
  { path: "/community", exact: false },
];

export function VisibilityWrapper({ children }: { children: ReactNode }) {
  const pathname = usePathname();
  const params = useParams();

  const isRestricted = useMemo(() => {
    return restrictedRoutes.some((route) => {
      if (route.exact && route.path === pathname) return true;

      if (!route.exact && pathname.startsWith(route.path)) return true;

      if (route.params) {
        const pathParts = pathname.split("/");
        const routeParts = route.path.split("/");

        if (pathParts.length !== routeParts.length) return false;

        return routeParts.every((part, index) => {
          if (part.startsWith(":")) {
            const paramName = part.slice(1);
            return (
              route.params!.includes(paramName) &&
              params[paramName] === pathParts[index]
            );
          }
          return part === pathParts[index];
        });
      }

      return false;
    });
  }, [pathname, params]);

  if (isRestricted) return null;

  return <>{children}</>;
}
