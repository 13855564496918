import dayjs from "dayjs";
import Image from "next/image";
import Link from "next/link";

import { apiImage } from "@/lib/utils";

export function MagPostHorizontalItem({
  thumb,
  label,
  title,
  href,
  publishedDate,
}: {
  thumb: string;
  label: string;
  title: string;
  href: string;
  publishedDate: string;
}) {
  return (
    <article
      className="hover:shadow-[0_0_0_12px_#f8fafc] hover:bg-slate-50"
      itemScope
      itemType="https://schema.org/Article"
    >
      <Link href={href} className="flex items-start gap-4">
        <Image
          src={apiImage(thumb, "small")}
          width={130}
          height={130}
          loading="lazy"
          className="object-cover overflow-hidden aspect-square max-md:w-24"
          alt={title}
          unoptimized
        />
        <div className="flex-1">
          <p className="underline mb-2 text-sm max-md:text-xs">{label}</p>
          <p className="mb-1 max-md:text-base text-xl line-clamp-2">{title}</p>
          <p className="max-md:text-sm text-[#84807D]">
            {dayjs(publishedDate).format("YYYY.MM.DD")}
          </p>
        </div>
      </Link>
    </article>
  );
}
