"use client";

import { ComponentProps } from "react";
import NextLink from "next/link";
import { usePathname } from "next/navigation";

import { cn } from "@/lib/utils";

export type LinkProps = ComponentProps<typeof NextLink> & {
  activeClassName?: string;
  withChildRoutes?: boolean;
};

export function Link({
  activeClassName,
  className,
  href,
  withChildRoutes,
  ...rest
}: LinkProps) {
  const pathname = usePathname();

  const checkActive = (p: string) => {
    if (withChildRoutes) return pathname.startsWith(p);
    return p === pathname;
  };

  return (
    <NextLink
      {...rest}
      href={href}
      className={cn(className, {
        [activeClassName ?? ""]: checkActive(
          typeof href === "object" ? href.pathname! : href
        ),
      })}
    />
  );
}
