import { forwardRef } from "react";
import Link from "next/link";

export const LinkedWrapper = forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement>
>(({ href, children, ...rest }, ref) => {
  if (!href) return children;

  if (href.startsWith("http"))
    return (
      <a ref={ref} href={href} {...rest}>
        {children}
      </a>
    );

  return (
    <Link ref={ref} href={href} {...rest}>
      {children}
    </Link>
  );
});

LinkedWrapper.displayName = "LinkedWrapper";
