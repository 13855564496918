"use client";

import React from "react";
import { User } from "react-iconly";
import { BookOpen, Heart, ShoppingCart } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";

import { useAuth } from "@/context";

export function MagazineMobileFooter() {
  const { isAuth } = useAuth();
  const pathname = usePathname();

  const links = [
    {
      href: "/magazine",
      label: "Сэтгүүл",
      Icon: BookOpen,
    },
    {
      href: "/magazine/cart",
      label: "Сагс",
      Icon: ShoppingCart,
    },
    { href: "/", label: "Гоодал веб", Icon: Heart },
    { href: isAuth ? "/account" : "/auth", label: "Профайл", Icon: User },
  ];

  return (
    <>
      <footer className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 md:hidden z-10">
        <nav className="h-14 flex items-center container">
          {links.map(({ href, label, Icon }) => {
            const isActive =
              href === "/" ? pathname === href : pathname.startsWith(href);
            return (
              <Link
                key={href}
                href={href}
                className={`flex flex-col items-center space-y-1 flex-1 ${
                  isActive ? "text-primary" : "text-[#84807D]"
                }`}
                suppressHydrationWarning
              >
                <Icon className="w-5 h-5" />
                <span
                  className={`text-[10px] ${
                    isActive ? "text-primary" : "text-[#84807D]"
                  }`}
                >
                  {label}
                </span>
              </Link>
            );
          })}
        </nav>
      </footer>
      <div className="h-14 md:hidden" />
    </>
  );
}
