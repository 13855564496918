"use client";

import { useEffect } from "react";

import { useAuth } from "@/context";
import { useFCM } from "@/hooks/useFCM";

export function FCMManager() {
  const { me } = useAuth();
  const { token, requestPermission, error } = useFCM();

  useEffect(() => {
    if (!token && !!me) requestPermission();
  }, [token, me]);

  if (error) console.error(error);
  return null;
}
