"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

import { Logo } from "@/components/custom";

export function AuthHeader() {
  const pathname = usePathname();

  return (
    <header className="container relative z-10 flex gap-2 items-center justify-between py-4">
      <Link href="/">
        <Logo
          type={
            [
              "/auth/result",
              "/auth/forgot",
              "/auth/forgot/verify",
              "/auth/forgot/pin",
            ].includes(pathname)
              ? "original"
              : "white"
          }
        />
      </Link>
      {pathname === "/auth/login" ? (
        <span className="text-sm max-lg:text-white">
          Хаяг байхгүй?{" "}
          <Link
            className="max-lg:text-white text-primary font-bold"
            href="/auth/register"
          >
            Бүртгүүлэх
          </Link>
        </span>
      ) : (
        <span className="text-sm">
          Хаяг байгаа?{" "}
          <Link
            className="max-lg:text-white text-primary font-bold"
            href="/auth/login"
          >
            Нэвтрэх
          </Link>
        </span>
      )}
    </header>
  );
}
