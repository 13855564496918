import Image from "next/image";
import Link from "next/link";

import { apiImage } from "@/lib/utils";

export function MoodItem({
  href,
  src = "/",
  name,
}: {
  href: string;
  src: string;
  name: string;
}) {
  return (
    <article
      itemScope
      itemType="https://schema.org/CreativeWork"
      className="text-center hover:underline"
    >
      <Link href={href} itemProp="url">
        <div className="aspect-square rounded-full relative mb-4 bg-slate-100 overflow-hidden">
          <Image
            src={apiImage(src, "small")}
            fill
            alt={`Mood item: ${name}`}
            loading="lazy"
            itemProp="image"
            className="object-cover"
            unoptimized
          />
        </div>
        <p className="text-lg truncate font-semibold" itemProp="name">
          {name}
        </p>
      </Link>
    </article>
  );
}

export function MoodItemSkeleton() {
  return (
    <article className="flex flex-col items-center animate-pulse">
      <div className="aspect-square rounded-full mb-4 bg-slate-100 w-full" />
      <p className="h-7 w-1/2 bg-slate-100 rounded-md" />
    </article>
  );
}
