"use client";

import { ReactNode, useEffect, useState } from "react";

import { cn } from "@/lib/utils";

export default function HeaderWrapper({ children }: { children: ReactNode }) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 0);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <header
        className={cn(
          "fixed top-0 left-0 right-0 z-50 transition-all duration-300 max-md:hidden group",
          isScrolled ? "bg-white text-black" : "bg-black text-white"
        )}
      >
        {children}
      </header>
      {/* <div className="h-16 max-md:hidden" /> */}
    </>
  );
}
