import Image from "next/image";
import Link from "next/link";

import { apiImage } from "@/lib/utils";

export function AlbumItem({
  href,
  src,
  count,
  name,
}: {
  href: string;
  src: string;
  count?: number;
  name: string;
}) {
  return (
    <article
      itemScope
      itemType="https://schema.org/MusicAlbum"
      className="rounded-sm hover:shadow-[0_0_0_12px_#f8fafc] hover:bg-slate-50"
    >
      <Link href={href} itemProp="url">
        <div className="aspect-square rounded-md relative mb-4 bg-slate-100 overflow-hidden">
          <Image
            src={apiImage(src, "medium")}
            fill
            alt={`Album item: ${name}`}
            loading="lazy"
            itemProp="thumbnailUrl"
            className="object-cover"
            unoptimized
          />
          {/* badge orj irne */}
        </div>
        <p
          className="text-lg truncate font-semibold max-md:text-sm"
          itemProp="name"
        >
          {name}
        </p>
        <span className="text-sm text-[#84807D] font-semibold max-md:text-xs">
          <span itemProp="numTracks">{count ?? 0}</span> аудио
        </span>
      </Link>
    </article>
  );
}

export function AlbumItemSkeleton() {
  return (
    <article className="animation-pulse">
      <div
        className="aspect-square rounded-md relative mb-5 bg-slate-100"
        itemProp="image"
      />
      <p className="bg-slate-100 h-7 w-2/3 rounded-md mb-2" />
      <span className="block bg-slate-100 h-4 w-1/3 rounded-md" />
    </article>
  );
}
