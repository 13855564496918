"use client";
import { ReactNode } from "react";
import { DialogTitle } from "@radix-ui/react-dialog";
import Image from "next/image";

import PauseIcon from "@/assets/icons/pause.svg";
import PlayIcon from "@/assets/icons/play.svg";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { AudioInfoType, AudioTypeEnum, useAudioContext } from "@/context";
import { AUDIO_DURATION_LIST } from "@/lib/constants";
import { ID } from "@/lib/schema";
import {
  apiAudio,
  apiImage,
  cn,
  formatDuration,
  humanizeDuration,
  removeHTML,
  storage,
} from "@/lib/utils";

import { AudioSlider } from "../audio-slider";

export function PodcastItem({
  src = "",
  id,
  title,
  description,
  durationStatic = 0,
  className,
  audioSrc,
  descAsDialog,
}: {
  src: string;
  id: ID;
  title: string;
  description: string;
  durationStatic?: number;
  className?: string;
  audioSrc: string;
  descAsDialog?: boolean;
}) {
  const {
    setAudio,
    isPlaying: playing,
    audio,
    formatTime,
    currentTime,
    duration,
    togglePlayPause,
  } = useAudioContext();
  const inProgress = (storage.get<
    Record<
      AudioInfoType["id"],
      {
        currentTime: number;
        duration: number;
      }
    >
  >(AUDIO_DURATION_LIST) || {})[`${AudioTypeEnum.PODCAST}.${id}`];
  const isCurrentAudio = audio?.id === `${AudioTypeEnum.PODCAST}.${id}`;
  const isPlaying = playing && isCurrentAudio;
  const remainedDuration = inProgress
    ? durationStatic! - inProgress.currentTime
    : durationStatic!;

  return (
    <article
      itemScope
      itemType="https://schema.org/PodcastEpisode"
      className={cn(
        "rounded-sm hover:shadow-[0_0_0_12px_#f8fafc] hover:bg-slate-50 flex gap-3 items-start",
        className
      )}
    >
      <button
        type="button"
        className="aspect-square rounded-sm relative flex items-center justify-center w-16 flex-[0_0_64px] max-md:w-14 max-md:flex-[0_0_56px] bg-slate-100 overflow-hidden"
        itemProp="image"
        onClick={() => {
          if (isCurrentAudio) return togglePlayPause();
          setAudio({
            id: `${AudioTypeEnum.PODCAST}.${id}`,
            src: apiAudio(audioSrc),
            thumbnail: apiImage(src, "xs"),
            title: title,
          });
        }}
      >
        <Image
          src={apiImage(src, "xs")}
          fill
          alt={`Podcast item: ${title}`}
          loading="lazy"
          itemProp="image"
          className="object-cover"
          unoptimized
        />
        <div className="p-2 rounded-full bg-[#FBF9F8] w-9 h-9 flex items-center justify-center z-[0]">
          {isPlaying ? (
            <>
              <PauseIcon
                style={{ width: 16, height: 16, color: "#F96F5D" }}
                className="max-sm:hidden"
              />
              <PauseIcon
                style={{ width: 14, height: 14, color: "#F96F5D" }}
                className="sm:hidden"
              />
            </>
          ) : (
            <>
              <PlayIcon
                suppressHydrationWarning
                fill={inProgress ? "#F96F5D" : "#393837"}
                style={{ width: 14, height: 14 }}
                className="max-sm:hidden"
              />
              <PlayIcon
                suppressHydrationWarning
                fill={inProgress ? "#F96F5D" : "#393837"}
                style={{ width: 10, height: 10 }}
                className="sm:hidden"
              />
            </>
          )}
        </div>
      </button>
      <div className="flex-1 min-w-0" suppressHydrationWarning>
        <header>
          <DescriptionWrapper
            description={removeHTML(description)}
            asDialog={!!descAsDialog}
          >
            <h2
              className="text-lg font-bold truncate mb-2 leading-none max-md:text-base"
              itemProp="name"
            >
              {title}
            </h2>
          </DescriptionWrapper>
        </header>
        <DescriptionWrapper
          description={removeHTML(description)}
          asDialog={!!descAsDialog}
        >
          <p
            className="line-clamp-3 mb-3 text-[#84807D] text-xs"
            itemProp="description"
          >
            {removeHTML(description)}
          </p>
        </DescriptionWrapper>
        {isCurrentAudio ? (
          <>
            <AudioSlider size="lg" />
            <div className="flex justify-between text-sm text-[#898480] mt-1">
              <span>{formatTime(currentTime)}</span>
              <span>
                {currentTime >= duration ? "" : "-"}
                {formatTime((duration || 0) - currentTime)}
              </span>
            </div>
          </>
        ) : (
          <p
            className={cn("text-xs", {
              "text-primary": inProgress,
            })}
            suppressHydrationWarning
          >
            <time
              itemProp="timeRequired"
              dateTime={`PT${Math.floor(remainedDuration / 60)}M${Math.round(remainedDuration % 60)}S`}
              aria-label={`Time required: ${formatDuration(remainedDuration)}`}
            >
              {humanizeDuration(remainedDuration)}
            </time>{" "}
            <span className={cn({ hidden: !inProgress })}>үлдсэн</span>
          </p>
        )}
      </div>
    </article>
  );
}

function DescriptionWrapper({
  description,
  asDialog,
  children,
}: {
  description: string;
  asDialog: boolean;
  children: ReactNode;
}) {
  if (asDialog)
    return (
      <DescriptionDialog description={description}>
        {children}
      </DescriptionDialog>
    );

  return children;
}

function DescriptionDialog({
  description,
  children,
}: {
  description: string;
  children: ReactNode;
}) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <button
          type="button"
          aria-label="open track description dialog"
          className="text-left"
        >
          {children}
        </button>
      </DialogTrigger>
      <DialogContent className="w-11/12 sm:max-w-[530px] rounded-xl sm:rounded-3xl">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">Тайлбар</DialogTitle>
        </DialogHeader>
        <p className="max-sm:text-center">{description}</p>
      </DialogContent>
    </Dialog>
  );
}

export function PodcastItemSkeleton() {
  return (
    <article className="flex gap-3 items-start animate-pulse">
      <div className="aspect-square rounded-sm w-20 bg-slate-100" />
      <div className="flex-1">
        <header className="h-7 mb-3 rounded-md bg-slate-100 w-full" />
        <div className="mb-4">
          <p className="h-4 mb-2 rounded-md bg-slate-100 w-full" />
          <p className="h-4 mb-2 rounded-md bg-slate-100 w-full" />
          <p className="h-4 mb-2 rounded-md bg-slate-100 w-1/2" />
        </div>
        <p className="h-3.5 rounded-md bg-slate-100 w-1/3" />
      </div>
    </article>
  );
}
