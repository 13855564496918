import dayjs from "dayjs";
import { ArrowRight } from "lucide-react";
import Image from "next/image";
import Link from "next/link";

import { apiImage } from "@/lib/utils";

export function MagPostItem({
  thumb,
  label,
  title,
  description,
  href,
  publishedDate,
}: {
  thumb: string;
  publishedDate: string;
  label: string;
  title: string;
  description: string;
  href: string;
}) {
  return (
    <article
      className="hover:shadow-[0_0_0_12px_#f8fafc] hover:bg-slate-50"
      itemScope
      itemType="https://schema.org/Article"
    >
      <Link href={href} className="flex flex-col gap-4 max-md:gap-2">
        <Image
          src={apiImage(thumb)}
          width={450}
          height={264}
          className="object-cover overflow-hidden aspect-[1/0.58] !w-full"
          alt={title}
          loading="lazy"
          itemProp="image"
          unoptimized
        />
        <div
          className="flex items-center gap-4 justify-between"
          itemProp="articleSection"
        >
          <p className="underline max-md:text-xs">{label}</p>
          <ArrowRight size={16} className="max-md:hidden" />
          <ArrowRight size={20} className="md:hidden" />
        </div>
        <div>
          <p
            className="mb-1 text-xl max-md:text-lg line-clamp-3"
            itemProp="headline"
          >
            {title}
          </p>
          <p className="line-clamp-3 max-md:text-sm" itemProp="description">
            {description}
          </p>
        </div>
        <p className="text-[#84807D] max-md:text-sm" itemProp="datePublished">
          {dayjs(publishedDate).format("YYYY.MM.DD")}
        </p>
      </Link>
    </article>
  );
}

export function MagPostItemSkeleton() {
  return null;
}
