import Image, { ImageProps } from "next/image";

export function Logo({
  type = "original",
  ...rest
}: Omit<ImageProps, "src" | "alt"> & { type?: "white" | "original" }) {
  if (type === "white")
    return (
      <Image
        src="/logo-white.svg"
        alt="goodali logo"
        width={126}
        height={36}
        {...rest}
      />
    );
  return (
    <Image
      src="/logo.svg"
      alt="goodali logo"
      width={126}
      height={36}
      {...rest}
    />
  );
}
