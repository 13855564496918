import Image from "next/image";
import Link from "next/link";

import { apiImage, currencyFormat } from "@/lib/utils";

export function BookItem({
  href,
  src = "/",
  name,
  sellingPrice,
  price,
}: {
  href: string;
  src: string;
  name: string;
  sellingPrice: number;
  price: number;
}) {
  return (
    <article
      itemScope
      itemType="https://schema.org/Book"
      className="rounded-sm hover:shadow-[0_0_0_12px_#f8fafc] hover:bg-slate-50"
    >
      <Link href={href} itemProp="url">
        <div className="aspect-book rounded-md relative mb-3 bg-slate-100 overflow-hidden">
          <Image
            src={apiImage(src, "medium")}
            fill
            alt={name}
            loading="lazy"
            itemProp="thumbnailUrl"
            className="object-cover"
            unoptimized
          />
          {/* badge */}
        </div>
        <p
          className="text-lg truncate mb-1 font-semibold max-md:text-sm"
          itemProp="name"
        >
          {name}
        </p>
        <link itemProp="additionalType" href="https://schema.org/Product" />
        <p
          className="flex items-center gap-2"
          itemScope
          itemType="https://schema.org/Offer"
        >
          <meta itemProp="priceCurrency" content="MNT" />
          <meta itemProp="price" content={sellingPrice.toString()} />
          <link itemProp="availability" href="https://schema.org/InStock" />
          <span className="text-sm text-[#84807D]">
            {currencyFormat(sellingPrice)}
          </span>
          {sellingPrice < price && (
            <span className="text-sm text-[#E25C56]">
              {currencyFormat(price)}
            </span>
          )}
        </p>
      </Link>
    </article>
  );
}

export function BookItemSkeleton() {
  return (
    <article className="animation-pulse">
      <div
        className="aspect-book rounded-md relative mb-5 bg-slate-100"
        itemProp="image"
      />
      <p className="bg-slate-100 h-7 w-2/3 rounded-md mb-2" />
      <span className="block bg-slate-100 h-4 w-1/3 rounded-md" />
    </article>
  );
}
