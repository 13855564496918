"use client";

import { useEffect, useRef, useState } from "react";

import { cn } from "@/lib/utils";

export function ExpandableText({
  text,
  lineClamp = 4,
  className,
  wrapperClassName,
  toggleClassName,
}: {
  text: string;
  lineClamp?: number;
  className?: string;
  wrapperClassName?: string;
  toggleClassName?: string;
}) {
  const [expand, setExpand] = useState(false);
  const [isTruncated, setIsTrancated] = useState(false);
  const elRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    setIsTrancated(
      !!elRef.current && elRef.current.clientHeight < elRef.current.scrollHeight
    );
  }, [text]);

  const clampClass = `line-clamp-${lineClamp}`;
  return (
    <div className={wrapperClassName}>
      <p
        itemProp="text"
        className={cn(className, { [clampClass]: !expand, expanded: expand })}
        ref={elRef}
      >
        {text}
      </p>
      {isTruncated && (
        <button
          type="button"
          onClick={() => setExpand(!expand)}
          className={cn("underline text-xs", toggleClassName)}
        >
          {expand ? "Хураах" : "Цааш унших"}
        </button>
      )}
    </div>
  );
}

export function ExpandableTextSkeleton() {
  return (
    <div className="space-y-2">
      <p className="h-4 bg-slate-100 animate-pulse rounded" />
      <p className="h-4 w-1/2 bg-slate-100 animate-pulse rounded" />
      <p className="h-4 w-1/3 bg-slate-100 animate-pulse rounded" />
    </div>
  );
}
