"use client";
import { Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "next/navigation";

import { FetchOptions, useFetch } from "@/hooks/useFetch/useFetch";
import { PaginatedResType } from "@/lib/schema";

import { LoadMore } from "./load-more";

export type InfinityScrollWrapperProps<T> = {
  initialData: PaginatedResType<T[]>;
  limit?: number;
  endpoint: string;
  className?: string;
  renderItem: (item: T, idx: number) => ReactNode;
  renderSkeletonItem: (idx: number) => ReactNode;
  staticQuery?: Record<string, string | string[]>;
  fetchOptions?: Omit<FetchOptions, "body" | "searchParams">;
};

export function InfinityScrollWrapper<T>({
  initialData,
  limit = 6,
  renderItem,
  renderSkeletonItem,
  endpoint,
  className,
  staticQuery = {},
  fetchOptions = {},
}: InfinityScrollWrapperProps<T>) {
  const [data, setData] = useState<PaginatedResType<T[]>>(initialData);
  const [fetchMore] = useFetch<PaginatedResType<T[]>>();
  const search = useSearchParams();

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const items = data?.data || [];
  const pagination = data?.pagination || {};

  const searchParams = useMemo(() => {
    let obj: Record<string, string> = {};

    search.forEach((v, k) => {
      obj[k] = v;
    });

    return obj;
  }, [search]);

  return (
    <>
      <div className={className}>
        {items.map((item, idx) => (
          <Fragment key={idx}>{renderItem(item, idx)}</Fragment>
        ))}
        <LoadMore
          hasMore={!!pagination.nextPage}
          loader={Array.from({
            length: Math.min(
              pagination.total - ((pagination.prevPage ?? 0) + 1) * limit,
              limit
            ),
          }).map((_, idx) => (
            <Fragment key={idx}>{renderSkeletonItem(idx)}</Fragment>
          ))}
          loadMore={() =>
            fetchMore(endpoint, {
              ...fetchOptions,
              searchParams: {
                ...searchParams,
                ...staticQuery,
                page: pagination.nextPage,
                limit,
              },
            }).then((res) =>
              setData((d) => ({
                data: [...(d.data || []), ...(res.data?.data || [])],
                pagination: res.data?.pagination! || {},
              }))
            )
          }
        />
      </div>
    </>
  );
}
