"use client";
import { ArrowRight } from "react-iconly";
import Link from "next/link";

export function SectionTitle({
  title,
  href,
}: {
  title: string;
  href?: string;
}) {
  return (
    <div className="flex items-center justify-between gap-2 mb-6 w-full">
      <h1 className="text-3xl font-bold max-sm:text-xl">{title}</h1>
      {href && (
        <Link
          href={href}
          className="bg-[#FBF9F8] flex gap-2 items-center py-2 px-3.5 rounded-md font-bold max-sm:text-xs"
        >
          Бүгд <ArrowRight size={16} />
        </Link>
      )}
    </div>
  );
}

export function SectionTitleSkeleton({ withHref }: { withHref?: boolean }) {
  return (
    <div className="flex items-center justify-between gap-2 mb-12 w-full animate-pulse">
      <div className="h-10 w-96 max-w-[50%] bg-slate-100 rounded-md" />
      {withHref && <span className="h-12 rounded-md bg-slate-100 w-24" />}
    </div>
  );
}
