"use client";

import { CloseSquare } from "react-iconly";

import { cn } from "@/lib/utils";

import { Button } from "../../ui/button";

export function ErrorResult({
  title = "Алдаа",
  description,
  action,
  className,
}: {
  title?: string;
  description?: string;
  action?: { label: string; onClick: () => void };
  className?: string;
}) {
  return (
    <div
      className={cn(
        "flex flex-col gap-20 items-center justify-center my-[20dvh] text-center",
        className
      )}
    >
      <div className="rounded-full flex items-center justify-center size-32 bg-[#FBF9F8]">
        <CloseSquare primaryColor="#F96F5D" filled size={64} />
      </div>
      <div>
        {title && <h5 className="text-4xl font-bold mb-4">{title}</h5>}
        {description && <p>{description}</p>}
      </div>
      {action && (
        <Button
          variant="default"
          className="px-10 rounded-2xl"
          type="button"
          onClick={action.onClick}
        >
          {action.label}
        </Button>
      )}
    </div>
  );
}
