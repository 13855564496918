import dayjs from "dayjs";
import { ArrowUpRight } from "lucide-react";
import Image from "next/image";
import Link from "next/link";

import { addZero, apiImage } from "@/lib/utils";

export function MagazineItem({
  href,
  src = "/",
  name,
  number,
  endDate,
  startDate,
}: {
  href: string;
  src: string;
  name: string;
  startDate: string;
  endDate: string;
  number: number;
}) {
  return (
    <article
      itemScope
      itemType="https://schema.org/Book"
      className="hover:shadow-[0_0_0_12px_#f8fafc] hover:bg-slate-50"
    >
      <Link href={href} itemProp="url">
        <div className="aspect-[0.8/1] relative mb-4 bg-slate-100 overflow-hidden">
          <Image
            src={apiImage(src, "medium")}
            fill
            alt={name}
            loading="lazy"
            itemProp="thumbnailUrl"
            className="object-cover"
            unoptimized
          />
          <div className="absolute w-full left-0 bottom-0 flex items-end justify-between gap-2">
            <div className="p-2 md:p-3 max-md:text-sm bg-[#131313] text-white">
              #{addZero(number)}
            </div>
            <div className="p-2 md:p-3 max-md:text-sm transition-colors bg-transparent text-white hover:bg-[#131313]">
              <ArrowUpRight size={20} className="md:hidden" />
              <ArrowUpRight size={24} className="max-md:hidden" />
            </div>
          </div>
        </div>
        <p
          className="text-lg truncate mb-0 md:mb-2 font-semibold max-md:text-sm"
          itemProp="name"
        >
          {name}
        </p>
        <span className="text-sm text-[#84807D]">
          {dayjs(startDate).format("YYYY.MM.DD")}-{dayjs(endDate).format("DD")}
        </span>
      </Link>
    </article>
  );
}

export function MagazineItemSkeleton() {
  return (
    <article className="animation-pulse">
      <div
        className="aspect-book rounded-md relative mb-5 bg-slate-100"
        itemProp="image"
      />
      <p className="bg-slate-100 h-7 w-2/3 rounded-md mb-2" />
      <span className="block bg-slate-100 h-4 w-1/3 rounded-md" />
    </article>
  );
}
