import { useEffect,useState } from 'react';

import { useAuth } from '@/context';
import { requestForToken } from '@/lib/firebase';

import { fetchOnClient } from './useFetch';

export function useFCM() {
  const {me} = useAuth();
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getToken = async () => {
      try {
        const currentToken = await requestForToken();
        setToken(currentToken);
      } catch (err) {
        setError((err as Error).message);
      }
    };

    getToken();
  }, []);

  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const currentToken = await requestForToken();
        setToken(currentToken);
        let os = 'web';
        if (navigator.userAgent.includes('Android')) os = 'android';
        else if (navigator.userAgent.includes('like Mac')) os = 'ios';
      
        if(currentToken) fetchOnClient({
          url: "/notification/register-token",
          options: { 
            cache: "no-store",
            method: 'POST',
            body: {
              "deviceType": os,
              "deviceToken": currentToken,
              "userId": me?.id
            } 
          },
        })
      } else {
        throw new Error('Notification permission denied');
      }
    } catch (err) {
      setError((err as Error).message);
    }
  };

  return { token, requestPermission, error };
}

