import { getApps,initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

export const initializeFirebase = () => {
  if (!getApps().length) {
    const app = initializeApp(firebaseConfig);
    if (typeof window !== 'undefined') {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register('/firebase-messaging-sw.js')
          // .then((registration) => {
          //   const messaging = getMessaging(app);
          //   onMessage(messaging, (payload: any) => {
          //     console.log({payload});
          //     registration.showNotification(payload?.notification?.title, {
          //       body: payload?.notification?.body,
          //       icon: '/favicon-32x32.png'
          //     });
          //   });
          // });
      }
    }
    return app;
  }
};

export const requestForToken = async () => {
  const app = initializeFirebase();
  try {
    const messaging = getMessaging(app);
    const currentToken = await getToken(messaging, { vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY });
    if (currentToken) {
      return currentToken;
    } else {
      throw new Error('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    throw new Error('An error occurred while retrieving token.');
  }
};

