"use client";

import { useState } from "react";
import { Menu, X } from "lucide-react";

import { Link } from "@/components/custom";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";
import { useFetch } from "@/hooks/useFetch";
import { VALIDATE_K } from "@/lib/constants";
import { MagazineCategoryItem, PaginatedResType } from "@/lib/schema";

export default function CornerMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [fetchData, { data, loading, error }] =
    useFetch<PaginatedResType<MagazineCategoryItem[]>>();

  if (error) return null;
  return (
    <Sheet
      open={isOpen}
      onOpenChange={(o) => {
        setIsOpen(o);
        fetchData("/magazine/category", {
          searchParams: { limit: 50 },
          next: { tags: [VALIDATE_K.magazine_category] },
        });
      }}
    >
      <SheetTrigger asChild>
        <button
          className="flex items-center justify-center"
          aria-label="Open menu"
        >
          <Menu className="h-6 w-6 transition-colors duration-300 group-[.bg-white]:text-black text-white max-md:text-black" />
        </button>
      </SheetTrigger>
      <SheetContent
        side="magazine"
        className="w-[300px] sm:w-[400px] px-0"
        hideCloseBtn
      >
        <div className="h-16 mx-6 flex items-start max-md:justify-end max-md:h-6 max-md:mb-4">
          <SheetClose>
            <X size={24} />
          </SheetClose>
        </div>
        <nav className="flex flex-col">
          <p className="text-[#84807D] py-2 px-6 border-b border-b-[#F2EFEC] uppercase font-bold text-sm">
            Булангууд
          </p>
          {loading ? (
            <>
              {Array.from({ length: 6 }).map((_, idx) => (
                <LinkSkeleton key={idx} />
              ))}
            </>
          ) : (
            data?.data?.map((c, idx) => (
              <Link
                href={`/magazine/category/${c.id}`}
                onClick={() => setIsOpen(false)}
                key={idx}
                className="py-5 px-6 flex items-center justify-between border-b border-b-[#F2EFEC]"
                activeClassName="bg-[#f2f2f2]"
              >
                <span className="flex-1 truncate min-w-0 font-bold text-base uppercase">
                  {c.name || "Unknown"}
                </span>
                <span className="text-[#84807D] text-base">
                  {c.totalArticles ?? 0}
                </span>
              </Link>
            ))
          )}
        </nav>
      </SheetContent>
    </Sheet>
  );
}

function LinkSkeleton() {
  return (
    <Link
      href="#"
      className="py-5 px-6 flex items-center justify-between animate-pulse"
    >
      <span className="flex-1 h-4 rounded-md bg-slate-100" />
      <span className="w-4 h-4 rounded-md bg-slate-100" />
    </Link>
  );
}
