import { ReactNode } from "react";

import { cn } from "@/lib/utils";

import { FormControl, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Input, InputProps } from "../ui/input";

export function FormInput({
  field,
  nodeBeforeError,
  nodeAfterError,
  label,
  prefix,
  suffix,
  ...rest
}: InputProps & {
  field: any;
  nodeBeforeError?: ReactNode;
  suffix?: ReactNode;
  prefix?: ReactNode;
  nodeAfterError?: ReactNode;
  label?: ReactNode;
}) {
  return (
    <FormItem>
      {label && (
        <FormLabel className="font-bold text-[#84807D] text-sm">
          {label}
        </FormLabel>
      )}
      <FormControl>
        <div
          className={cn(
            "relative border-b  border-b-[#F2EFEC] has-[:focus]:border-b-primary flex gap-2 items-center",
            { "border-none": rest.type === "hidden" }
          )}
        >
          {prefix}
          <Input
            className="flex-1 border-0 rounded-none placeholder-[#84807D] px-0 focus:outline-none !focus-visible:ring-offset-0 focus-visible:shadow-none !focus-visible:outline-none !ring-0 !ring-offset-0"
            {...field}
            {...rest}
          />
          {suffix}
        </div>
      </FormControl>
      {nodeBeforeError}
      <FormMessage />
      {nodeAfterError}
    </FormItem>
  );
}
