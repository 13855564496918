"use client";
import { ArrowRight } from "react-iconly";
import dayjs from "dayjs";
import Image from "next/image";
import Link from "next/link";

import { apiImage, removeHTML } from "@/lib/utils";

export function ArticleItem({
  src = "/",
  href,
  title,
  description,
  duration,
  date,
}: {
  src: string;
  href: string;
  title: string;
  description: string;
  duration?: number;
  date: string;
}) {
  return (
    <article
      className="flex gap-4 items-start rounded-sm hover:shadow-[0_0_0_12px_#f8fafc] hover:bg-slate-50"
      itemScope
      itemType="https://schema.org/Article"
    >
      <div className="aspect-square rounded-sm relative flex items-center justify-center w-20 object-cover max-sm:w-14 overflow-hidden">
        <Image
          src={apiImage(src, "xs")}
          fill
          alt={title}
          loading="lazy"
          itemProp="thumbnailUrl"
          className="object-cover"
          unoptimized
        />
      </div>
      <div className="flex-1 min-w-0">
        <header className="truncate">
          <h2
            className="text-lg font-bold truncate mb-1 leading-none max-sm:text-base max-sm:leading-none"
            itemProp="headline"
          >
            {title}
          </h2>
          <p
            className="text-xs text-[#84807D] mb-3 max-sm:mb-2"
            itemProp="timeRequired"
            content={`PT${duration}M`}
          >
            <time dateTime={`PT${duration}M`} itemProp="duration">
              {duration} мин унших •{" "}
            </time>
            <time
              itemProp="datePublished"
              dateTime={dayjs(date).format("YYYY-MM-DD")}
            >
              {dayjs(date).format("YYYY.MM.DD")}
            </time>
          </p>
        </header>
        <p
          className="line-clamp-2 mb-3 text-sm max-sm:text-xs"
          itemProp="description"
        >
          {removeHTML(description)}
        </p>
        <footer>
          <Link
            href={href}
            itemProp="url"
            className="flex items-center text-xs text-primary gap-2 hover:underline"
          >
            Дэлгэрэнгүй <ArrowRight primaryColor="#F96F5D" size={12} />
          </Link>
        </footer>
      </div>
    </article>
  );
}

export function ArticleItemSkeleton() {
  return (
    <article className="flex gap-4 items-start animate-pulse">
      <div className="aspect-square rounded-sm w-20 bg-slate-100" />
      <div className="flex-1">
        <header className="h-7 mb-3 rounded-md bg-slate-100 w-full" />
        <div className="mb-4">
          <p className="h-4 mb-2 rounded-md bg-slate-100 w-full" />
          <p className="h-4 mb-2 rounded-md bg-slate-100 w-full" />
          <p className="h-4 mb-2 rounded-md bg-slate-100 w-1/2" />
        </div>
        <p className="h-3.5 rounded-md bg-slate-100 w-1/3" />
      </div>
    </article>
  );
}
