import dayjs from "dayjs";
import Image from "next/image";
import Link from "next/link";

export function VideoItem({
  href,
  src,
  views,
  name,
  date,
  duration,
}: {
  href: string;
  src: string;
  views?: number;
  name: string;
  date: string;
  duration?: string;
}) {
  return (
    <article
      itemScope
      itemType="https://schema.org/VideoObject"
      className="rounded-sm hover:shadow-[0_0_0_12px_#f8fafc] hover:bg-slate-50"
    >
      <Link href={href} itemProp="url">
        <div className="aspect-banner rounded-md relative mb-4 bg-slate-100 overflow-hidden">
          <Image
            src={src}
            fill
            alt={name}
            loading="lazy"
            itemProp="thumbnailUrl"
            className="object-cover"
            unoptimized
          />
          {duration && (
            <span
              className="py-1 px-1.5 text-white text-[10px] bg-foreground/50 rounded-sm absolute bottom-4 right-4 z-[1]"
              itemProp="duration"
            >
              {duration}
            </span>
          )}
        </div>
        <p
          className="text-xl truncate mb-1 font-semibold leading-none max-md:text-base max-md:mb-0"
          itemProp="name"
        >
          {name}
        </p>
        <span className="text-sm text-[#84807D]">
          <time
            itemProp="uploadDate"
            dateTime={dayjs(date).format("YYYY-MM-DD")}
          >
            {dayjs(date).format("YYYY.MM.DD")}
          </time>{" "}
          {typeof views !== "undefined" && (
            <>
              • <span itemProp="interactionCount">{views ?? 0} үзсэн</span>
            </>
          )}
        </span>
      </Link>
    </article>
  );
}

export function VideoItemSkeleton() {
  return (
    <article className="animation-pulse">
      <div
        className="aspect-banner rounded-md relative mb-5 bg-slate-100"
        itemProp="image"
      />
      <p className="bg-slate-100 h-7 w-2/3 rounded-md mb-2" />
      <span className="block bg-slate-100 h-4 w-1/3 rounded-md" />
    </article>
  );
}
